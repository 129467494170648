import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { MemoryRouter } from 'react-router-dom'
import Modal from 'react-modal'
import { appDomain } from './api/util'

function mountWidget(id = 'suitable-widget') {
  const widgetMountPoint = document.createElement('div')
  widgetMountPoint.id = id

  document.body.appendChild(widgetMountPoint)
  Modal.setAppElement(widgetMountPoint)

  mountCss()

  ReactDOM.render(
    <React.StrictMode>
      <MemoryRouter>
        <App />
      </MemoryRouter>
    </React.StrictMode>,
    widgetMountPoint
  )
}

function mountCss() {
  if (process.env.NODE_ENV === 'development') return

  const style = document.createElement('link')
  style.href = `${appDomain}/main.css`
  style.rel = 'stylesheet'

  document.head.appendChild(style)
}

mountWidget()
