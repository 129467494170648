import React, { useState } from 'react'
import { IoMdSearch } from 'react-icons/io'
import searchIcon from '../../assets/search-mobile.png'
function WidgetBrand({ allBrands, setBrand, nextStep, brand, configure }) {
  const [filterText, setFilterText] = useState('')
  const [isActive, setIsActive] = useState(false)
  const displayBrands = () => {
    return allBrands.data
      .filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase()))
      .map(brand => {
        console.log(brand)
        return (
          <div
            key={brand.id}
            className={'option-field-harry'}
            onClick={() => {
              setBrand(brand)
              nextStep()
            }}
          >
            <div id='style-link' className={configure?.website.includes('alderandgreen') ? 'style-link style-link-alder' : 'style-link'}>
              {brand.name}
            </div>
          </div>
        )
      })
  }

  return (
    <>
      <div
        className={ 
          configure?.website.includes('alderandgreen') ?
          isActive
          ? allBrands.data.filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase())).length > 0
            ? 'outer-input-field-alder active'
            : 'outer-input-field-alder active active-border'
          : 'outer-input-field-alder'
          : brand?.shopType === 'wordpress'
            ? isActive
              ? allBrands.data.filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase())).length > 0
                ? 'outer-input-field active'
                : 'outer-input-field active active-border'
              : 'outer-input-field'
            : isActive
            ? allBrands.data.filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase())).length > 0
              ? 'outer-input-field-harry active'
              : 'outer-input-field-harry active active-border'
            : 'outer-input-field-harry'
        }
      >
        <label htmlFor='input-icon'>
          {window.innerWidth > 763 ? <IoMdSearch className='search-icon' /> : <img src={searchIcon} className='search-icon' alt={'search icon'} />}
        </label>
        <input
          autoComplete='off'
          className={brand?.website.includes('alderandgreen') ? `input-field input-step-search input-field-alder` : `input-field input-step-search`}
          id='modalInputField'
          name='cc'
          type='text'
          placeholder='Type the name of your brand'
          value={filterText}
          onChange={e => {
            setFilterText(e.target.value)
            if (e.target.value.length > 0) {
              setIsActive(true)
            } else {
              setIsActive(false)
            }
          }}
        />
      </div>
      <div
        className={
          brand?.shopType === 'wordpress'
            ? isActive && allBrands.data.filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase())).length > 0
              ? brand?.website.includes('alderandgreen') ? 'select-field-alder select-field active' : 'select-field active'
              : brand?.website.includes('alderandgreen') ? 'select-field-alder select-field' : 'select-field'
            : isActive && allBrands.data.filter(brand => brand.name.toLowerCase().includes(filterText.toLowerCase())).length > 0
            ? brand?.website.includes('alderandgreen') ? 'select-field-alder active select-field-harry' : 'select-field-harry active'
            : brand?.website.includes('alderandgreen') ? 'select-field-alder select-field-harry' : 'select-field-harry'
        }
      >
        {filterText ? displayBrands() : null}
      </div>
    </>
  )
}

export default WidgetBrand
