import React from 'react'
import WidgetNavigation from './WidgetNavigation/WidgetNavigation'
import { useState } from 'react'
import WidgetBrand from './WidgetBrand/WidgetBrand'
import { getBrand, getBrands, getFits, getProducts, getResults, getStyles } from '../api/requests'
import { useEffect } from 'react'
import WidgetStyle from './WidgetStyle/WidgetStyle'
import WidgetFit from './WidgetFit/WidgetFit'
import WidgetSize from './WidgetSize/WidgetSize'
import WidgetResults from './WidgetResults/WidgetResults'
import { IoIosArrowBack } from 'react-icons/io'
import PowerByWidget from './PowerByWidget'
export default function Widget({ vendorId, setModal, modal }) {
  const [step, setStep] = useState(null)
  const [brand, setBrand] = useState('')
  const [configuration, setConfiguration] = useState()
  const [style, setStyle] = useState('')
  const [fit, setFit] = useState('')
  const [size, setSize] = useState('')
  const [results, setResults] = useState('')

  const [displayStyle, setDisplayStyle] = useState('')

  const [allBrands, setAllBrands] = useState('')
  const [allStyles, setAllStyles] = useState('')
  const [allFits, setAllFits] = useState('')
  const [allSizes, setAllSizes] = useState('')
  const [switchStyles, setSwitchStyles] = useState(false)
  const [widgetColors, setWidgetColors] = useState({})
  const setColor = (color, configurations) => {
    setWidgetColors(color)
    document.documentElement.style.setProperty('--app-banner-color', color.appBannerBackgroundColor)
    document.documentElement.style.setProperty('--app-banner-text-color', color.appBannerTextColor)
    document.documentElement.style.setProperty('--app-back-button-color', color.appBackButtonColor)
    document.documentElement.style.setProperty('--app-border-color', color.appBorderColor)
    document.documentElement.style.setProperty('--app-result-primary-color', color.appResultPrimaryColor)
    document.documentElement.style.setProperty('--app-result-secondary-color', color.appResultSecondaryColor)
    document.documentElement.style.setProperty('--app-result-button-color', color.appResultButtonBackgroundColor)
    document.documentElement.style.setProperty('--app-result-button-text-color', color.appResultButtonTextColor)
    document.documentElement.style.setProperty('--app-font', configurations.widgetFont)
    if (configuration?.shopType === 'wordpress') {
      document.querySelector('.brand-logo').src = configurations.widgetLogo
    } else if (configuration?.website.includes('alderandgreen')) {
      document.querySelector('.brand-logo-alder').src = configurations.widgetLogo
    } else {
      document.querySelector('.brand-logo-harry').src = configurations.widgetLogo
    }
    document.querySelector('.shirt-image').src = configurations.widgetAvatar
    // document.getElementById()
  }

  useEffect(() => {
    if (fit) fetchProducts()
    //eslint-disable-next-line
  }, [fit])

  useEffect(() => {
    if (size) fetchResults()
    //eslint-disable-next-line
  }, [size])
  useEffect(() => {
    if (step === 0) fetchFits()
    //eslint-disable-next-line
  }, [modal, step])
  async function fetchStyles() {
    setAllStyles(await getStyles())
  }
  async function fetchFits() {
    setAllFits(await getFits())
  }
  async function fetchResults() {
    const myFit = JSON.parse(localStorage.getItem('myFit'))
    let data = {}
    if (myFit) {
      data = myFit
    } else {
      data = {
        brandId: brand.id,
        vendorId: vendorId,
        styleId: style.id,
        fitId: fit.id,
        size: size.shirt.size,
        styleName: style.name,
      }
      localStorage.setItem('myFit', JSON.stringify(data))
    }

    const results = await getResults(data).catch(err => {
      console.log('API call failed', err)
    })
    setDisplayStyle(data.styleName)
    setResults(results ? results.data : {})
  }

  useEffect(() => {
    async function fetchBrands() {
      setAllBrands(await getBrands())
      const configurations = await getBrand()
      setColor(await JSON.parse(configurations.widgetColor), configurations)
      setConfiguration(configurations)
      const myFit = await JSON.parse(await localStorage.getItem('myFit'))
      if (myFit) {
        setAllStyles(await getStyles())
        await setBrand(myFit.brandId)
        await setStyle(myFit.styleId)
        await setFit(myFit.fitId)
        await setSize(myFit.size)
        setStep(4)
      } else {
        setStep(0)
      }
    }
    fetchBrands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchProducts() {
    const products = await getProducts()
    let relevantProducts = products.data.filter(product => {
      return product.brand.id === brand.id && product.shirt.fit.id === fit.id && product.shirt.fit.style.id === style.id
    })
    setAllSizes(relevantProducts)
  }

  const changeStyle = newStyle => {
    // if (newStyle.id === style.id) return

    // setStyle(newStyle)

    setDisplayStyle(newStyle.name)
  }

  const stepContent = () => {
    switch (step) {
      case 0:
        
      configuration?.website.includes('alderandgreen')?document.querySelector('button#suitable-opener').innerHTML = 'FIND MY SIZE':document.querySelector('button#suitable-opener').innerHTML = 'Find My Fit'
        return allBrands ? (
          <WidgetBrand setBrand={setBrand} nextStep={nextStep} allBrands={allBrands} brand={configuration} configure={configuration} />
        ) : (
          <div className='loading'></div>
        )
      case 1:
        return allStyles ? (
          <WidgetStyle setStyle={setStyle} nextStep={nextStep} allStyles={allStyles} allFits={allFits} brand={brand} configure={configuration} />
        ) : (
          <div className='loading'></div>
        )

      case 2:
        return allFits ? (
          <WidgetFit
            setFit={setFit}
            nextStep={nextStep}
            allFits={allFits}
            selectedBrand={brand.id}
            selectedStyle={style.id}
            configure={configuration}
          />
        ) : (
          <div className='loading'></div>
        )

      case 3:
        return allSizes ? (
          <WidgetSize nextStep={nextStep} allSizes={allSizes} setSize={setSize} configure={configuration} />
        ) : (
          <div className='loading'></div>
        )
      case 4:
        document.querySelector('button#suitable-opener').innerHTML = 'View My Best Fit'
        return results ? <WidgetResults displayStyle={displayStyle} results={results[displayStyle]} configure={configuration} setModal={() => setModal()} /> : <div className='loading'></div>
      default:
        return <div></div>
    }
  }

  const previousStep = () => {
    switch (step) {
      case 1:
        setBrand('')
        setStep(0)
        break
      case 2:
        setStyle('')
        setStep(1)
        break
      case 3:
        setFit('')
        setStep(2)
        break
      case 4:
        setSize('')
        setStyle('')
        setBrand('')
        setFit('')
        setAllFits('')
        setAllSizes('')
        setStep(0)
        localStorage.removeItem('myFit')
        configuration?.website.includes('alderandgreen')?
        document.querySelector('button#suitable-opener').innerHTML = 'FIND MY SIZE'
        :document.querySelector('button#suitable-opener').innerHTML = 'Find My Fit'
        break
      default:
        return
    }
  }

  const nextStep = () => {
    switch (step) {
      case 0:
        fetchStyles()
        setStep(1)
        break
      case 1:
        fetchFits()
        setStep(2)
        break
      case 2:
        //fetch is called in useEffect as the info from the previous step is needed in filtering
        setStep(3)
        break
      case 3:
        setStep(4)
        break
      default:
        return
    }
  }

  const searchText = () => {
    switch (step) {
      case 0:
        return 'What is the brand of your shirt?'
      case 1:
        return 'What is the style of your shirt?'
      case 2:
        return 'What is the fit of your shirt?'
      case 3:
        return configuration?.shopType === 'wordpress' ? 'What is the collar size of your shirt?' : 'What is the size of your shirt?'
      default:
        return
    }
  }
  const headerText = () => {
    switch (step) {
      case 0:
        return (
          <>
            <>
              { configuration?.website.includes('alderandgreen') ? 
              window.innerWidth < 763
              ? [`Please enter the following details about a shirt that fits you best.`]
              : [`To help us guarantee you get the best fit, please enter the following details about a shirt that`, <br /> ,`fits you best.`]
              : configuration?.shopType === 'wordpress'
                ? 'Please enter the following details about a shirt that fits you best.'
                : window.innerWidth < 763
                ? 'Please enter the following details about a shirt that fits you best.'
                : 'To help us guarantee you get the best fit, please enter the following details about a shirt that fits you best.'}
            </>
          </>
        )
      case 1:
        return window.innerWidth > 763 ? `Okay, ${brand.name}. 3 more questions.` : [`Okay, ${brand.name}.`, <br />, `3 more questions.`]
      case 2:
        return window.innerWidth > 763
          ? `Okay, ${style.name} ${brand.name}. 2 more questions.`
          : [`Okay, ${style.name} ${brand.name}.`, <br />, `2 more questions.`]
      case 3:
        return window.innerWidth > 763
          ? `Okay, ${style.name} ${brand.name}, ${fit.name} Fit. Last question.`
          : [`Okay, ${style.name} ${brand.name}, ${fit.name} Fit.`, ` Last question.`]
      default:
        return
    }
  }
  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <WidgetNavigation headerText={headerText} step={step} setModal={() => setModal()} brand={configuration} colors={widgetColors} />
      <div
        id='result-container'
        className={
          step === 4
            ? 
            configuration?.website.includes('alderandgreen') ? 'content-container content-container-results-alder'
            : configuration?.shopType === 'wordpress'
              ? 'content-container content-container-results'
              : 'content-container content-container-results-harry'
            : configuration?.website.includes('alderandgreen') ? 'content-container-alder' : 'content-container'
        }
      >
        <div className={configuration?.website.includes('alderandgreen') ? 'image-container-alder' : 'image-container'}>
          {step === 4 && (
            <div className={`change-style ${configuration?.shopType === `wordpress` ? `change-style-harry` : ``}`}>
              <p className='change-style-p'>Change Style</p>
              <div className='button-group'>
                {allStyles.data.map((styleItem, index) => {
                  if (index === 0 || index === 1) {
                    return (
                      <button
                        onClick={() => changeStyle(styleItem)}
                        className={styleItem.name === displayStyle ? 'style-button-active-harry' : 'style-button-harry'}
                      >
                        {styleItem.name}
                      </button>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>

              <div className='switch-container'>
                {allStyles?.data?.length > 1 ? (
                  <div className='label-container'>
                    <label className={allStyles?.data[0]?.name === displayStyle ? 'label-bold label-switch' : 'label-switch'}>
                      {allStyles?.data[0]?.name} <br />
                      results
                    </label>
                    <input
                      type='checkbox'
                      id='switch'
                      checked={allStyles?.data[1]?.name === displayStyle}
                      className='checkbox'
                      onClick={e => {
                        if (switchStyles === false) {
                          changeStyle(allStyles?.data[1])
                          setSwitchStyles(true)
                        } else {
                          changeStyle(allStyles?.data[0])
                          setSwitchStyles(false)
                        }
                      }}
                    />
                    <label for='switch' className='toggle'></label>

                    <label className={allStyles?.data[1]?.name === displayStyle ? 'label-bold label-switch' : 'label-switch'}>
                      {allStyles?.data[1]?.name} <br />
                      results
                    </label>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          <img className='shirt-image' src={``} alt={''} />
        </div>
        <div className='search-container-suitable'>
          {step < 4 && (
            <>
              <h2 className={configuration?.website.includes('alderandgreen') ? 'search-title-alder' : 'search-title'}>{searchText()}</h2>
            </>
          )}
          {stepContent()}
        </div>
      </div>
      {step > 0 && (
        <div className={`${configuration?.website.includes('alderandgreen') ? 'back-container-alder' : 'back-container'}`} onClick={previousStep}>
          {step === 4 && window.innerWidth < 763 ? (
          <div>
           <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_115_107)">
            <path d="M7.31163 11.2257L8.83825 10.4216C8.97758 10.3483 9.14035 10.3332 9.29076 10.3798C9.44117 10.4265 9.5669 10.5309 9.64029 10.6703C9.71367 10.8096 9.72871 10.9724 9.68208 11.1228C9.63545 11.2732 9.53098 11.3989 9.39166 11.4723L6.9436 12.7617C6.80428 12.8351 6.6415 12.8502 6.49109 12.8035C6.34068 12.7569 6.21495 12.6524 6.14157 12.5131L4.85324 10.0672C4.77986 9.92783 4.76482 9.76506 4.81145 9.61464C4.85808 9.46423 4.96255 9.33851 5.10187 9.26512C5.2412 9.19173 5.40397 9.1767 5.55438 9.22333C5.70479 9.26996 5.83052 9.37442 5.90391 9.51375L6.3411 10.3438C6.80621 9.04044 7.64081 7.90076 8.74294 7.06392C9.84508 6.22708 11.1671 5.7293 12.5474 5.63138C13.9278 5.53346 15.3068 5.83964 16.5161 6.51252C17.7253 7.18539 18.7124 8.19585 19.3568 9.42047C20.2374 11.0922 20.4178 13.0453 19.8585 14.85C19.2992 16.6548 18.0459 18.1635 16.3743 19.0443C14.7024 19.925 12.7491 20.1054 10.9442 19.5458C9.13927 18.9863 7.63054 17.7327 6.7499 16.0608L7.80057 15.5074C8.53443 16.9006 9.79171 17.9453 11.2958 18.4116C12.7999 18.8779 14.4277 18.7275 15.8209 17.9937C17.2142 17.2598 18.2589 16.0025 18.7251 14.4984C19.1914 12.9943 19.0411 11.3666 18.3072 9.97332C17.75 8.91516 16.8862 8.04994 15.8289 7.49096C14.7717 6.93198 13.5702 6.70529 12.382 6.84059C11.1937 6.97589 10.074 7.46686 9.16951 8.24921C8.26498 9.03156 7.61779 10.0688 7.31268 11.2252L7.31163 11.2257Z" fill="#00264D"/>
            </g>
            <defs>
            <clipPath id="clip0_115_107">
            <rect width="19" height="19" fill="white" transform="translate(0 8.85449) rotate(-27.7766)"/>
            </clipPath>
            </defs>
            </svg>

          </div>
            ) : (
              configuration?.website.includes('alderandgreen') ? window.innerWidth < 763 ? <svg xmlns="http://www.w3.org/2000/svg" className='back-icon-small' width="9" height="15" viewBox="0 0 9 15" fill="none">
              <path d="M3.05497 7.50317L8.66644 1.89146C8.82095 1.73732 8.90595 1.53122 8.90595 1.31146C8.90595 1.09159 8.82095 0.88561 8.66644 0.73122L8.17473 0.239756C8.02046 0.0851219 7.81424 0 7.59449 0C7.37473 0 7.16875 0.0851219 7.01436 0.239756L0.333022 6.92098C0.178022 7.07585 0.0931435 7.2828 0.0937533 7.5028C0.0931435 7.72378 0.1779 7.93049 0.333022 8.08549L7.00814 14.7602C7.16253 14.9149 7.36851 15 7.58839 15C7.80814 15 8.01412 14.9149 8.16863 14.7602L8.66022 14.2688C8.98009 13.9489 8.98009 13.4282 8.66022 13.1084L3.05497 7.50317Z" fill="#00264D"/>
              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22" fill="none">
              <path d="M4.88036 11.0046L13.1105 2.77412C13.3371 2.54803 13.4618 2.24576 13.4618 1.92345C13.4618 1.60096 13.3371 1.29886 13.1105 1.07242L12.3893 0.351612C12.1631 0.124815 11.8606 -3.05176e-05 11.5383 -3.05176e-05C11.216 -3.05176e-05 10.9139 0.124815 10.6875 0.351612L0.888163 10.1507C0.66083 10.3779 0.536342 10.6814 0.537236 11.0041C0.536342 11.3282 0.660651 11.6314 0.888163 11.8587L10.6783 21.6483C10.9048 21.8751 11.2069 22 11.5294 22C11.8517 22 12.1538 21.8751 12.3804 21.6483L13.1014 20.9275C13.5705 20.4584 13.5705 19.6946 13.1014 19.2256L4.88036 11.0046Z" fill="#00264D" fill-opacity="0.7"/>
              </svg> : <IoIosArrowBack className='arrow-back' />
          )}
          <p className={`start-again ${configuration?.website.includes('alderandgreen') ? 'start-again-text-alder' : ''}`}>{step === 4 ? 'Start again' : ''}</p>
        </div>
      )}
        <PowerByWidget wrapperClass={'mobile-powered-by-container'} infoIconClass={'mobile-info-icon-wrapper'} />
    </div>
  )
}
