import { useEffect, useState } from 'react'
import './styles/App.scss'

import Modal from 'react-modal'
import Widget from './components/Widget'

function App(props) {
  const [isModalOpen, setModalState] = useState(false)
  const [vendorId, setVendorId] = useState('')

  const [, setStyleId] = useState('')

  function onPageLoad() {
    const openButton = document.getElementById('suitable-opener')
    document.head.innerHTML += '  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" /> '
    setVendorId(openButton.getAttribute('data-suitable-id'))
    setStyleId(openButton.getAttribute('data-suitable-style') || '')
    setModalState(openButton.getAttribute('data-state') === 'open')
    const myFit = JSON.parse(localStorage.getItem('myFit'))
    if (myFit) {
      document.querySelector('button#suitable-opener').innerHTML = 'View My Best Fit'
      document.addEventListener('DOMContentLoaded', function (event) {
        console.log('DOMContentLoaded in render:')
        const colorSizeId = Array.from(document.querySelectorAll('input'))
          .find(el => el.value === myFit.mySize + '"')
          .getAttribute('id')
        console.log('DOMContentLoaded in render:', colorSizeId)
        document.getElementById(colorSizeId).click()
        if (localStorage.getItem('variantId') !== null && localStorage.getItem('variantId') !== undefined) {
          // selecting color
          document.querySelector(`[id*=${localStorage.getItem('variantId')}]`).click()
        }
      })
    }
    // what do we do if the button does not exist?
    if (!openButton) return

    openButton.addEventListener('click', () => {
      setModalState(true)
    })
  }

  useEffect(() => {
    setTimeout(onPageLoad)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal isOpen={isModalOpen} onRequestClose={() => setModalState(false)} style={customModalStyles} className='content'>
      <Widget vendorId={vendorId} setModal={() => setModalState(false)} modal={isModalOpen} />
    </Modal>
  )
}

const customModalStyles = {
  content: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    border: 0,
    padding: 0,
    minHeight: 616,
    maxHeight: 700,
    margin: 'auto',
    inset: '10px',
  },
  overlay: {
    backgroundColor: 'rgba(128, 128, 128, 0.4)',
  },
}

export default App
