import React, { useEffect, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { appDomain, redirectURLWordpress, redirectURLShopify, redirectURL, redirectURLShopifyAlder } from '../../api/util'
import { getBrand, getFit } from '../../api/requests'

const WidgetResults = ({ results, configure, setModal, displayStyle }) => {
  const [alternateUI, setAlternateUI] = useState(false)
  const [alternateUIForMatch, setAlternateUIForMatch] = useState(false)
  const [sleeveSizes, setSleeveSizes] = useState({})
  const [sleeveSizeForCart, setSleeveSizeForCart] = useState('')
  const hasResults = results && Object.keys(results).length !== 0
  const initialResult = hasResults ? results[0] : {}
  const [selectedResult, setSelectedResult] = useState(initialResult)
  const [selectedSleeveSize, setSelectedSleeveSize] = useState('')
  const [productMatch, setProductMatch] = useState(false)
  const [productPage, setProductPage] = useState(false)
  const [currentBrand, setCurrentBrand] = useState({})
  const widgetInitializingURL = window.location.href
  const harry = widgetInitializingURL.includes('harry')
  const style = widgetInitializingURL.includes(displayStyle?.toLowerCase())
  const sleeveSizesFunction = (configurations, fit, widgetInputs) => {
    if (configurations.shopType === 'wordpress') {
      if (checkStringExistsInURL('product/', widgetInitializingURL) === true) {
        let sleeveSizes = []
        let sleeveSizeElement = document.getElementById('sleeve-size') ? document.getElementById('sleeve-size').options : {}
        for (let i = 0; i < sleeveSizeElement.length; i++) {
          if (sleeveSizeElement[i].value !== '') {
            sleeveSizes.push(sleeveSizeElement[i].value)
          }
        }
        sleeveSizes = { ...sleeveSizes }
        if (results) {
          if (checkStringExistsInURL(results[0].fit.split(' ')[0].toLowerCase(), widgetInitializingURL) !== true) {
            results.forEach((result, index) => {
              if (index === 1 && checkStringExistsInURL(result.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) === true) {
                setAlternateUI(true)
              } else {
                setAlternateUIForMatch(true)
              }
            })
          } else {
            setAlternateUI(false)
            setAlternateUIForMatch(false)
          }
        }
        return sleeveSizes
      } else {
        console.log('Wordpress, but Not Product Page')
        return {}
      }
    } else if (configurations.shopType === 'shopify') {
      if (checkStringExistsInURL('products/', widgetInitializingURL) === true) {
        console.log('Shopify Product Page')
        if (results) {
          if (checkStringExistsInURL(results[0].fit.split(' ')[0].toLowerCase(), widgetInitializingURL) !== true) {
            console.log('Shopify Product Fit Matched => results[0].fit[0].toLowerCase >>>>>>>>>>', results[0].fit.toLowerCase())
            results.forEach((result, index) => {
              if (index === 1 && checkStringExistsInURL(result.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) === true) {
                setAlternateUI(true)
                console.log('AlternateUI')
              } else {
                setAlternateUIForMatch(true)
                console.log('Alternate UI 3D')
              }
            })
          } else {
            setAlternateUI(false)
            setAlternateUIForMatch(false)
          }
        }
        return {}
      } else {
        console.log('Shopify, but Not Product Page')
        return {}
      }
    } else {
      console.log('Neither Wordpress Nor Shopify')
      return {}
    }
  }
  const checkStringExistsInURL = (string, url) => {
    return url.indexOf(string) > -1
  }

  const checkProductMatch = product => {
    if (checkStringExistsInURL('product/', widgetInitializingURL) === true || checkStringExistsInURL('products/', widgetInitializingURL) === true) {
      if (checkStringExistsInURL(product.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) === true) {
        console.log(`CheckProductMatch is checking if URL has it's fit ${product.fit.split(' ')[0].toLowerCase()} 
                          = ${checkStringExistsInURL(product.fit.split(' ')[0].toLowerCase(), widgetInitializingURL)}`)
        setProductMatch(true)
      } else {
        configure?.website.includes('alderandgreen') ? setProductMatch(true) : setProductMatch(false)
      }
      setSelectedResult(product)
    }
  }

  const selectSleeveSize = result => {
    let sleeveSizes = []
    let sleeveSizeElement = document.getElementById('sleeve-size') ? document.getElementById('sleeve-size').options : {}
    for (let i = 0; i < sleeveSizeElement.length; i++) {
      if (sleeveSizeElement[i].value !== '') {
        sleeveSizes.push(sleeveSizeElement[i].value.slice(0, 2))
      }
    }
    sleeveSizes = { ...sleeveSizes }
    console.log('Sleeve Sizes .................', sleeveSizes)
    if (result.fit === 'Regular') {
      if (result.size === '16.25' || result.size === '16.5') {
        if (Object.values(sleeveSizes).includes('35')) {
          setSleeveSizeForCart('35in Sleeve')
          setSelectedSleeveSize('35in Sleeve')
        } else {
          sleeveSizes = Object.values(sleeveSizes).filter(sleeveSize => parseInt(sleeveSize) > 35)
          setSleeveSizeForCart(`${sleeveSizes[0]}in Sleeve`)
          setSelectedSleeveSize(`${sleeveSizes[0]}in Sleeve`)
        }
      } else {
        if (Object.values(sleeveSizes).includes('34')) {
          setSleeveSizeForCart('34in Sleeve')
          setSelectedSleeveSize('34in Sleeve')
        } else {
          sleeveSizes = Object.values(sleeveSizes).filter(sleeveSize => parseInt(sleeveSize) > 34)
          setSleeveSizeForCart(`${sleeveSizes[0]}in Sleeve`)
          setSelectedSleeveSize(`${sleeveSizes[0]}in Sleeve`)
        }
      }
    } else if (result.fit === 'Slim') {
      if (Object.values(sleeveSizes).includes('34')) {
        setSleeveSizeForCart('34in Sleeve')
        setSelectedSleeveSize('34in Sleeve')
      } else {
        sleeveSizes = Object.values(sleeveSizes).filter(sleeveSize => parseInt(sleeveSize) > 34)
        setSleeveSizeForCart(`${sleeveSizes[0]}in Sleeve`)
        setSelectedSleeveSize(`${sleeveSizes[0]}in Sleeve`)
      }
    }
  }

  useEffect(() => {
    setProductMatch(false)
    setAlternateUI(false)
    setAlternateUIForMatch(false)
    setProductPage(false)
    if (checkStringExistsInURL('product/', widgetInitializingURL) === true || checkStringExistsInURL('products/', widgetInitializingURL) === true) {
      setProductPage(true)
    }
    async function fetchConfigurations() {
      const getData = JSON.parse(localStorage.getItem('myFit'))
      const fit = await getFit(getData.fitId)
      const configurations = await getBrand()
      setCurrentBrand(configurations)
      let sleeves = sleeveSizesFunction(configurations, fit.data, getData)
      if (configurations?.shopType === 'wordpress') {
        setSleeveSizes(sleeves)
      }
    }

    fetchConfigurations()
    setSelectedResult(initialResult)
    selectSleeveSize(initialResult)
    if (results) {
      if (configure?.website.includes('alderandgreen')) {
        checkProductMatch(results[0])
      } else {
        if (checkStringExistsInURL(results[0].fit.split(' ')[0].toLowerCase(), widgetInitializingURL) !== true) {
          let preferFitcheck = false
          results.forEach(result => {
            if (checkStringExistsInURL(result.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) && preferFitcheck === false) {
              preferFitcheck = true
              checkProductMatch(result)
            }
          })
        } else if (checkStringExistsInURL(results[0].fit.split(' ')[0].toLowerCase(), widgetInitializingURL) === true) {
          checkProductMatch(results[0])
        } else if (checkStringExistsInURL(results[1].fit.split(' ')[0].toLowerCase(), widgetInitializingURL) === true) {
          checkProductMatch(results[1])
        }
      }
    }
    //eslint-disable-next-line
  }, [results])
  const handleFilter = async () => {
    const brand = await getBrand()
    setCurrentBrand(brand)
    if (configure?.website.includes('alderandgreen')) {
      let fit = selectedResult.fit + ' Fit'
      fit = fit.split(' ').join('%20')

      let size = selectedResult.size.split('-').join('.') + '"'
      window.location = redirectURLShopifyAlder(brand.website, selectedResult.style, fit, size)
    } else {
      switch (brand.shopType) {
        case 'wordpress':
          window.location = redirectURLWordpress(brand.website, selectedResult.style, selectedResult.fit, selectedResult.size)
          break
        case 'shopify':
          window.location = redirectURLShopify(brand.website, selectedResult.style, selectedResult.fit, selectedResult.size)
          break
        default:
          window.location = redirectURL(brand.website, selectedResult.style, selectedResult.fit, selectedResult.size)
          break
      }
    }
  }
  const handleCart = async () => {
    if (configure?.website.includes('alderandgreen')) {
      let newFit = JSON.parse(localStorage.getItem('myFit'))
      newFit.mySize = selectedResult.size.split('-').join('.')
      localStorage.setItem('myFit', JSON.stringify(newFit))

      // selecting color size
      const colorSizeId = Array.from(document.querySelectorAll('input'))
        .find(el => el.value === selectedResult.size + '"')
        .getAttribute('id')
      document.getElementById(colorSizeId).click()

      // setting color id in localStorage
      let colorArray = document.querySelector('[id*="swatch-0"]:checked').getAttribute('id')
      colorArray = colorArray.split('-')
      let colorId = colorArray[0] + '-' + colorArray[1] + '-' + colorArray[2]
      localStorage.setItem('variantId', JSON.stringify(colorId))

      // clicking fit btn
      let buttonText = selectedResult.fit + ' Fit'
      Array.from(document.querySelectorAll('a'))
        .find(el => el.textContent === buttonText)
        .click()

      // closing modal
      setModal()
    } else {
      const brand = await getBrand()
      const changeEvent = new Event('change', { bubbles: true })
      switch (brand.shopType) {
        case 'wordpress':
          let collarSize = selectedResult.size.split('.')
          document.getElementById('sleeve-size').value = sleeveSizeForCart
          document.getElementById('pa_size').value = collarSize.join('-')
          document.getElementById('sleeve-size').dispatchEvent(changeEvent)
          document.getElementById('pa_size').dispatchEvent(changeEvent)
          document.querySelector('.variations_form').submit()
          break
        case 'shopify':
          console.log(selectedResult.size)
          document.getElementById('productSelect-product-template-option-0').value = selectedResult.size
          document.getElementById('productSelect-product-template-option-0').dispatchEvent(changeEvent)
          document.querySelector('#addToCartForm-product-template').submit()
          break
        default:
          break
      }
    }
  }
  console.log('the style is here', style, harry)
  const getSize = result => (isNaN(parseFloat(result.size)) ? 'Size ' + result.size : result.size + ' inch collar')
  if (hasResults && productPage && alternateUIForMatch === true) {
    if (currentBrand.shopType === 'wordpress') {
      document.getElementById('result-container').className = 'content-container-results content-container'
    }
    let preferFit = false
    return (
      <>
        <h2 className='search-title text-darkgreen'>Your best fit</h2>

        {window.innerWidth > 763 ? (
          (configure?.website.includes('alderandgreen') ? <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one <br />you own</p> : <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one you own</p>)
        ) : configure?.website.includes('alderandgreen') ? (
          <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like <br /> the one you own.</p>
        ) : (
          <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one you own</p>
        )}

        {results
          .filter((data, index) => {
            if (index === 0) {
              return data
            }
            if (checkStringExistsInURL(data.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) && preferFit === false) {
              preferFit = true
              return data
            } else {
              return false
            }
          })
          .map(result => {
            return (
              <>
                {checkStringExistsInURL(result.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) ? (
                  <>
                    <h2 className='search-title alternate text-darkgreen margin-alternate'>Prefer a {result.fit} fit?</h2>

                    {window.innerWidth > 763 ? (
                      <p className='search-subtitle text-darkgreen-65'>
                        The following shirt is the closest size match to the one you own in a {result.fit} Fit
                      </p>
                    ) : (
                      <p className='search-subtitle text-darkgreen-65'>
                        The following shirt is the closest size match to the one you own in a {result.fit} Fit
                      </p>
                    )}
                  </>
                ) : null}
                <div
                  className={
                    configure?.website.includes('alderandgreen')
                      ? selectedResult.id === result.id
                        ? 'result-item-alder'
                        : 'result-item-alder result-item-alder-disabled'
                      : currentBrand?.shopType === 'wordpress'
                      ? selectedResult.id === result.id
                        ? 'result-item'
                        : 'result-item result-item-disabled'
                      : selectedResult.id === result.id
                      ? 'result-item-harry'
                      : 'result-item-harry result-item-harry-disabled'
                  }
                  onClick={() => {
                    checkProductMatch(result)
                    selectSleeveSize(result)
                  }}
                >
                  <div
                    className={
                      configure?.website.includes('alderandgreen')
                        ? 'result-title-alder result-title-padding-alder'
                        : currentBrand?.shopType === 'wordpress'
                        ? 'result-title result-title-padding'
                        : 'result-title-harry result-title-padding-harry'
                    }
                  >
                    <p>
                      {result.fit} Fit, {getSize(result)}
                    </p>
                    {selectedResult.id === result.id ? <img src={`${appDomain}/checked.png`} alt=''></img> : <div className='empty-circle' />}
                  </div>
                  <div className='result-progress'>
                    <div
                      className={currentBrand?.shopType === 'wordpress' ? 'progress-percentage' : 'progress-percentage-harry'}
                      style={{
                        width: result.match * 100 === 100 ? (window.innerWidth > 763 ? `100.1%` : `100.2%`) : `${result.match * 100}%`,
                        borderBottomRightRadius: result.match * 100 === 100 ? (window.innerWidth > 763 ? '5px' : '4px') : '0px',
                      }}
                    >
                      <div className='percentage-text'>{Math.round(result.match * 100)}%</div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        {productMatch && Object.entries(sleeveSizes).length > 0 ? (
          <>
            <h2 className='fit-title'>Select sleeve size</h2>
            <div className='styles-container-results'>
              {Object.entries(sleeveSizes).map((value, key) => {
                return (
                  <div
                    className={
                      selectedSleeveSize === value[1]
                        ? 'style-item style-item-compact style-item-sleeveSize'
                        : 'style-item style-item-compact style-item-sleeveSize sleeveSize-disabled'
                    }
                    onClick={() => {
                      setSleeveSizeForCart(value[1])
                      setSelectedSleeveSize(value[1])
                    }}
                  >
                    {sleeveSizes[key].split(' ')[0].substring(0, sleeveSizes[key].split(' ')[0].length - 2)}″
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        {harry ? (
          productPage && productMatch && style ? (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
                <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
                {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          ) : (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
                <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          )
        ) : productPage && productMatch ? (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
              <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
              {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        ) : (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
              <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        )}
      </>
    )
  } else if (hasResults && productPage && alternateUI === true) {
    return (
      <>
        <h2 className='search-title text-darkgreen'>Your best fit</h2>

        {window.innerWidth > 763 ? (
          (configure?.website.includes('alderandgreen') ? <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one <br />you own</p> : <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one you own</p>)
        ) : configure?.website.includes('alderandgreen') ? (
          <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like <br /> the one you own.</p>
        ) : (
          <p className='search-subtitle text-darkgreen-65'>The following shirt will fit you the most like the one you own</p>
        )}
        {results.slice(0, 2).map(result => {
          return (
            <>
              {checkStringExistsInURL(result.fit.split(' ')[0].toLowerCase(), widgetInitializingURL) ? (
                <>
                  <h2 className='search-title alternate text-darkgreen margin-alternate'>Prefer a {result.fit} fit?</h2>
                  <p className='search-subtitle text-darkgreen-65'>
                    The following shirt is the closest size match to the one you own in a {result.fit} Fit
                  </p>
                </>
              ) : null}
              <div
                className={
                  configure?.website.includes('alderandgreen')
                    ? selectedResult.id === result.id
                      ? 'result-item-alder'
                      : 'result-item-alder result-item-alder-disabled'
                    : currentBrand?.shopType === 'wordpress'
                    ? selectedResult.id === result.id
                      ? 'result-item'
                      : 'result-item result-item-disabled'
                    : selectedResult.id === result.id
                    ? 'result-item-harry'
                    : 'result-item-harry result-item-harry-disabled'
                }
                onClick={() => {
                  checkProductMatch(result)
                  selectSleeveSize(result)
                }}
              >
                <div
                  className={
                    configure?.website.includes('alderandgreen')
                      ? 'result-title-alder result-title-padding-alder'
                      : currentBrand?.shopType === 'wordpress'
                      ? 'result-title result-title-padding'
                      : 'result-title-harry result-title-padding-harry'
                  }
                >
                  <p>
                    {result.fit} Fit, {getSize(result)}
                  </p>
                  {selectedResult.id === result.id ? <img src={`${appDomain}/checked.png`} alt=''></img> : <div className='empty-circle' />}
                </div>
                <div className='result-progress'>
                  <div
                    className={currentBrand?.shopType === 'wordpress' ? 'progress-percentage' : 'progress-percentage-harry'}
                    style={{
                      width: result.match * 100 === 100 ? (window.innerWidth > 763 ? `100.1%` : `100.2%`) : `${result.match * 100}%`,
                      borderBottomRightRadius: result.match * 100 === 100 ? (window.innerWidth > 763 ? '5px' : '4px') : '0px',
                    }}
                  >
                    <div className='percentage-text'>{Math.round(result.match * 100)}%</div>
                  </div>
                </div>
              </div>
            </>
          )
        })}
        {productMatch && Object.entries(sleeveSizes).length > 0 ? (
          <>
            <h2 className='fit-title'>Select sleeve size</h2>
            <div className='styles-container-results'>
              {Object.entries(sleeveSizes).map((value, key) => {
                return (
                  <div
                    className={
                      selectedSleeveSize === value[1]
                        ? 'style-item style-item-compact style-item-sleeveSize'
                        : 'style-item style-item-compact style-item-sleeveSize sleeveSize-disabled'
                    }
                    onClick={() => {
                      setSleeveSizeForCart(value[1])
                      setSelectedSleeveSize(value[1])
                    }}
                  >
                    {sleeveSizes[key].split(' ')[0].substring(0, sleeveSizes[key].split(' ')[0].length - 2)}″
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        {harry ? (
          productPage && productMatch && style ? (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
                <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
                {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          ) : (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
                <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          )
        ) : productPage && productMatch ? (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
              <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
              {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        ) : (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
              <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        )}
      </>
    )
  } else if (hasResults && productPage) {
    return (
      <>
        <h2 className='search-title'>Your best fit</h2>

        {window.innerWidth > 763 ? (
          (configure?.website.includes('alderandgreen') ? <p className='search-subtitle'>The following shirt will fit you the most like the one <br />you own</p> : <p className='search-subtitle'>The following shirt will fit you the most like the one you own</p>)
        ) : configure?.website.includes('alderandgreen') ? (
          <p className='search-subtitle'>The following shirt will fit you the most like <br /> the one you own.</p>
        ) : (
          <p className='search-subtitle'>The following shirt will fit you the most like the one you own</p>
        )}

        {results.slice(0, 2).map(result => {
          return (
            <div
              className={
                configure?.website.includes('alderandgreen')
                  ? selectedResult.id === result.id
                    ? 'result-item-alder'
                    : 'result-item-alder result-item-alder-disabled'
                  : currentBrand?.shopType === 'wordpress'
                  ? selectedResult.id === result.id
                    ? 'result-item'
                    : 'result-item result-item-disabled'
                  : selectedResult.id === result.id
                  ? 'result-item-harry'
                  : 'result-item-harry result-item-harry-disabled'
              }
              onClick={() => {
                checkProductMatch(result)
                selectSleeveSize(result)
              }}
            >
              <div
                className={
                  configure?.website.includes('alderandgreen')
                    ? 'result-title-alder'
                    : currentBrand?.shopType === 'wordpress'
                    ? 'result-title'
                    : 'result-title-harry'
                }
              >
                <p>
                  {result.fit} Fit, {getSize(result)}
                </p>
                {selectedResult.id === result.id ? <img src={`${appDomain}/checked.png`} alt=''></img> : <div className='empty-circle' />}
              </div>
              <div className='result-progress'>
                <div
                  className={currentBrand?.shopType === 'wordpress' ? 'progress-percentage' : 'progress-percentage-harry'}
                  style={{
                    width: result.match * 100 === 100 ? (window.innerWidth > 763 ? `100.1%` : '100.2%') : `${result.match * 100}%`,
                    borderBottomRightRadius: result.match * 100 === 100 ? (window.innerWidth > 763 ? '5px' : '4px') : '0px',
                  }}
                >
                  <div className='percentage-text'>{Math.round(result.match * 100)}%</div>
                </div>
              </div>
            </div>
          )
        })}
        {productMatch && Object.entries(sleeveSizes).length > 0 ? (
          <>
            <h2 className='fit-title'>Select sleeve size</h2>
            <div className='styles-container-results'>
              {Object.entries(sleeveSizes).map((value, key) => {
                return (
                  <div
                    className={
                      selectedSleeveSize === value[1]
                        ? 'style-item style-item-compact style-item-sleeveSize'
                        : 'style-item style-item-compact style-item-sleeveSize sleeveSize-disabled'
                    }
                    onClick={() => {
                      setSleeveSizeForCart(value[1])
                      setSelectedSleeveSize(value[1])
                    }}
                  >
                    {sleeveSizes[key].split(' ')[0].substring(0, sleeveSizes[key].split(' ')[0].length - 2)}″
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        {harry ? (
          productPage && productMatch && style ? (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
                <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
                {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          ) : (
            <>
              <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
                <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
              </button>
            </>
          )
        ) : productPage && productMatch ? (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleCart}>
              <p>{configure?.website.includes('alderandgreen') ? 'Select this size' : 'Add to bag'}</p>{' '}
              {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        ) : (
          <>
            <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
              <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
            </button>
          </>
        )}
      </>
    )
  } else if (results) {
    return (
      <>
        <h2 className='search-title'>Your best fit</h2>

        {window.innerWidth > 763 ? (
          (configure?.website.includes('alderandgreen') ? <p className='search-subtitle'>The following shirt will fit you the most like the one <br />you own</p> : <p className='search-subtitle'>The following shirt will fit you the most like the one you own</p>)
        ) : configure?.website.includes('alderandgreen') ? (
          <p className='search-subtitle'>The following shirt will fit you the most like <br /> the one you own.</p>
        ) : (
          <p className='search-subtitle'>The following shirt will fit you the most like the one you own</p>
        )}
        {results.slice(0, 2).map(result => {
          return (
            <div
              className={
                configure?.website.includes('alderandgreen')
                  ? selectedResult.id === result.id
                    ? 'result-item-alder'
                    : 'result-item-alder result-item-alder-disabled'
                  : currentBrand?.shopType === 'wordpress'
                  ? selectedResult.id === result.id
                    ? 'result-item'
                    : 'result-item result-item-disabled'
                  : selectedResult.id === result.id
                  ? 'result-item-harry'
                  : 'result-item-harry result-item-harry-disabled'
              }
              onClick={() => {
                setSelectedResult(result)
              }}
            >
              <div
                className={
                  configure?.website.includes('alderandgreen')
                    ? 'result-title-alder'
                    : currentBrand?.shopType === 'wordpress'
                    ? 'result-title'
                    : 'result-title-harry'
                }
              >
                <p>
                  {result.fit} Fit, {getSize(result)}
                </p>
                {selectedResult.id === result.id ? <img src={`${appDomain}/checked.png`} alt=''></img> : <div className='empty-circle' />}
              </div>
              <div className='result-progress'>
                <div
                  className={currentBrand?.shopType === 'wordpress' ? 'progress-percentage' : 'progress-percentage-harry'}
                  style={{
                    width: result.match * 100 === 100 ? (window.innerWidth > 763 ? `100.1%` : `100.2%`) : `${result.match * 100}%`,
                    borderBottomRightRadius: result.match * 100 === 100 ? (window.innerWidth > 763 ? '5px' : '4px') : '0px',
                  }}
                >
                  <div className='percentage-text'>{Math.round(result.match * 100)}%</div>
                </div>
              </div>
            </div>
          )
        })}
        <button className={currentBrand?.shopType === 'wordpress' ? 'filter-button-nick' : configure?.website.includes('alderandgreen') ? 'filter-button filter-button-alder' : 'filter-button'} onClick={handleFilter}>
          <p>Filter by this fit</p> {configure?.website.includes('alderandgreen') ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
<path d="M6.29292 8.03732L1.11909 13.8788C0.976626 14.0392 0.898255 14.2538 0.898255 14.4825C0.898255 14.7114 0.976626 14.9258 1.11909 15.0865L1.57245 15.5981C1.71468 15.759 1.90482 15.8477 2.10744 15.8477C2.31006 15.8477 2.49997 15.759 2.64232 15.5981L8.80258 8.64335C8.94549 8.48213 9.02375 8.26671 9.02319 8.0377C9.02375 7.80768 8.94561 7.59251 8.80258 7.43116L2.64805 0.483153C2.5057 0.322189 2.31579 0.233582 2.11306 0.233582C1.91044 0.233582 1.72053 0.322189 1.57807 0.483153L1.12482 0.994736C0.829892 1.32771 0.829892 1.86976 1.12482 2.20261L6.29292 8.03732Z" fill="white"/>
</svg> : <IoIosArrowForward className='arrow-forward arrow-forward-add' />}{' '}
        </button>
      </>
    )
  } else return <div className='no-result-found'>No result found, please try another shirt you love.</div>
}

export default WidgetResults
