import React from 'react'

function WidgetStyle({ allStyles, setStyle, nextStep, allFits = {}, brand, configure }) {
  const displayStyles = () => {
    return allStyles.data
      .filter(style => {
        const styleId = allFits?.data
          .filter(fit => (fit.style.id === style.id && fit.brandId === brand.id ? style : null))
          .reduce((prevValue, value, index, self) => {
            return self.indexOf(value) === index ? style : null
          }, 0)
        if (Object.entries(styleId).length > 0) {
          if (styleId.id === style.id) {
            return style
          } else {
            return null
          }
        } else {
          return null
        }
      })
      .sort()
      .map((style, key) => {
        if (window.innerWidth > 763) {
          const num = allStyles.data.length
          let fitborder = ''
          if (num % 3 !== 0) {
            const rows = Math.floor(num / 3)
            var second_last_row_last_box = rows * 3
          }
          if (configure?.shopType === 'wordpress') {
            if (key === 0) {
              fitborder += 'border-top-left '
            }
            if (key === 0 && num === 3) {
              fitborder += 'border-bottom-left'
            }
            if (key === second_last_row_last_box - 1) {
              fitborder += 'border-bottom-right '
            }
            if (key === 2 && num > 3) {
              fitborder += 'border-top-right '
            }
            if (key === num - 1) {
              fitborder += 'border-bottom-right '
            }
            if (key === second_last_row_last_box) {
              fitborder += 'border-bottom-left '
            }
          } else {
            if (key === 0) {
              fitborder += 'border-top-left-harry '
            }
            if (key === 0 && num === 3) {
              fitborder += 'border-bottom-left-harry '
            }
            if (key === second_last_row_last_box - 1) {
              fitborder += 'border-bottom-right-harry '
            }
            if (key === 2 && num > 3) {
              fitborder += 'border-top-right-harry '
            }
            if (key === num - 1) {
              fitborder += 'border-bottom-right-harry '
            }
            if (key === second_last_row_last_box) {
              fitborder += 'border-bottom-left-harry '
            }
          }

          return (
            <div
              key={key}
              className={`${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''} style-item style-fit ${fitborder}`}
              onClick={() => {
                setStyle(style)
                nextStep()
              }}
            >
              {style.name}
            </div>
          )
        } else {
          let num = allStyles.data
            .filter(style => {
              const styleId = allFits?.data
                .filter(fit => (fit.style.id === style.id && fit.brandId === brand.id ? style : null))
                .reduce((prevValue, value, index, self) => {
                  return self.indexOf(value) === index ? style : null
                }, 0)
              if (Object.entries(styleId).length > 0) {
                if (styleId.id === style.id) {
                  return style
                } else {
                  return null
                }
              } else {
                return null
              }
            })
            .sort().length
          let bordernone = ''
          let border = ''
          let borderDull = ''
          console.log(key)
          if (key === 0 && key === num - 1) {
            border = 'border-top-right border-top-left border-bottom-right border-bottom-left'
          }
          if (key === 0) {
            border += 'border-top-right border-top-left'
          }
          if (key === num - 1) {
            border += 'border-bottom-right border-bottom-left'
          }
          if (key !== num - 1) {
            bordernone += 'border-none'
          }
          if (configure?.shopType === 'wordpress') {
            if (key !== 0) {
              borderDull += 'border-dull'
            }
          } else {
            if (key !== 0) {
              borderDull += 'border-dull-harry'
            }
          }
          return (
            <div
              key={key}
              id={`style ${key}`}
              className={`${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''} style-item style-fit-custom ${border} ${bordernone} border ${borderDull}`}
              onClick={() => {
                setStyle(style)
                nextStep()
              }}
            >
              {style.name}
            </div>
          )
        }
      })
  }
  return (
    <>
      {
        <div
          className={
            configure?.shopType === 'wordpress'
              ? window.innerWidth > 763
                ? 'styles-container fullwidth'
                : 'styles-container'
              : window.innerWidth > 763
              ? 'styles-container-harry fullwidth'
              : 'styles-container-harry'
          }
        >
          <> {displayStyles()} </>
        </div>
      }
    </>
  )
}

export default WidgetStyle
