import React from 'react'

function WidgetSize({ allSizes, setSize, nextStep, configure }) {
  const displaySizes = () => {
    const allSizesIntegerAscendingOrder = [...allSizes].sort((a, b) => a.shirt.size - b.shirt.size)
    const allSizesAlphabeticsAscendingOrder = [...allSizes].sort((a, b) => a.shirt.collarMeasurement - b.shirt.collarMeasurement)
    if (!isNaN(allSizes[0].shirt.size * 1)) {
      allSizes = allSizesIntegerAscendingOrder
    } else {
      allSizes = allSizesAlphabeticsAscendingOrder
    }
    return allSizes.map((size, key) => {
      const num = allSizes.length
      const rows = Math.floor(num / 6)
      if (window.innerWidth > 763) {
        let clas = ''
        if (configure?.shopType === 'wordpress') {
          if (key % 6 === 0) {
            if (num / 6 > rows && key === rows * 6) {
              clas += 'border-bottom-left '
            }
            if (num / 6 === rows && key === (rows - 1) * 6) {
              clas += 'border-bottom-left '
            }
          }
          if (key === 0 && num <= 5) {
            clas += 'border-bottom-left '
          }
          if (allSizes.length <= 6 && key === 0) {
            clas += 'border-bottom-left '
          }
          if ((key + 1) % 6 === 0 && rows * 6 === key + 1) {
            clas += 'border-bottom-right '
          }
          if (key % 6 === 0 && rows * 6 === key) {
            clas += 'border-bottom-left'
          }
        } else {
          if (key % 6 === 0) {
            if (num / 6 > rows && key === rows * 6) {
              clas += 'border-bottom-left-harry '
            }
            if (num / 6 === rows && key === (rows - 1) * 6) {
              clas += 'border-bottom-left-harry '
            }
          }
          if (key === 0 && num <= 5) {
            clas += 'border-bottom-left-harry-harry '
          }
          if (allSizes.length <= 6 && key === 0) {
            clas += 'border-bottom-left-harry-harry '
          }
          if ((key + 1) % 6 === 0 && rows * 6 === key + 1) {
            clas += 'border-bottom-right-harry-harry '
          }
          if((key + 1) % 6 === 0 && (key + 7) < num)
          {
            clas += 'border-bottom-right-harry-harry '
          }
          if (key % 6 === 0 && rows * 6 === key) {
            clas += 'border-bottom-left-harry-harry '
          }
        }

        return (
          <div
            key={key}
            className={`${clas} style-item style-item-compact ${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''}`}
            onClick={() => {
              setSize(size)
              nextStep()
            }}
          >
            {isNaN(size.shirt.size * 1) ? size.shirt.size : `${size.shirt.size}”`}
          </div>
        )
      } else {
        const num = allSizes.length
        let clas = ''
        let bor = ''
        var second_last_row_last_box
        if (num % 3 !== 0) {
          const rows = Math.floor(num / 3)
          second_last_row_last_box = rows * 3
        }
        if (num % 3 === 0 && num - 3 === key) {
          console.log('num % 3 === 0 && num - 3 === key', num % 3 === 0 && num - 3 === key, key)
          bor += 'border-bottom-left'
        }
        if (key % 3 !== 0) {
          clas += 'border-left-0 '
        }
        if (key > 2) {
          clas += 'border-top-0 '
        }
        if (key === 0) {
          clas += 'border-top-left '
        }
        if (key === 0 && num === 3) {
          clas += 'border-bottom-left '
        }
        if (key === second_last_row_last_box - 1) {
          clas += 'border-bottom-right '
        }

        if (key === 2 && num > 3) {
          clas += 'border-top-right '
        }

        if (key === num - 1) {
          clas += 'border-bottom-right '
        }

        if (key === second_last_row_last_box) {
          clas += 'border-bottom-left '
        }

        if ((key + 1) + 3 > num) {
          clas += 'size-bottom-shadow'
        }

        return (
          <div
            key={key}
            className={`${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''} style-item style-item-compact-responsive ${clas} ${bor}`}
            onClick={() => {
              setSize(size)
              nextStep()
            }}
          >
            {isNaN(size.shirt.size * 1) ? ((configure?.website.includes('alderandgreen') && window.innerWidth < 763) ? size.shirt.size.split(`"`)[0] : size.shirt.size) : ((configure?.website.includes('alderandgreen') && window.innerWidth < 763) ? size.shirt.size : `${size.shirt.size}”`)}
          </div>
        )
      }
    })
  }

  return (
    <>
      <div
        className={
          configure?.shopType === 'wordpress'
            ? window.innerWidth > 763
              ? 'styles-container fullwidth'
              : 'styles-container'
            : window.innerWidth > 763
            ? 'styles-container-harry fullwidth'
            : 'styles-container-harry'
        }
      >
        {displaySizes()}
      </div>
    </>
  )
}

export default WidgetSize
