import axios from 'axios'
/**
 * Because AWS Amplify has some limitations, API proxies are as follows:
 * - ${domain}/api/dev/
 * - ${domain}/api/staging/
 * - ${domain}/api/
 *
 * Where <domain> is any of the stage domains. This means to access the dev api
 * we proxy dev.domain/api/dev.
 */

// const http = axios.create({
//   baseURL: `http://localhost:3000/local/${suffix}`,
// })

const env = process.env.REACT_APP_STAGE
const domain = process.env.MAIN_DOMAIN

const http = axios.create({
  baseURL: `https://${env}.api.suitable.tech/`,
})

export const getBrands = async () => {
  console.log(env)
  console.log(domain)
  const brands = await http.get('brands')
  return brands
}


export const getBrand = async () => {
  let brandData = '';
  const id = document.getElementById('suitable-opener').getAttribute('data-suitable-id')
  const brand = await getBrands();
  if (Array.isArray(brand.data)) {
    brand.data.forEach(data => {
      if (id === data.id) {
        brandData =  data
      }
    })
  }
  return brandData
}
export const getStyles = async () => {
  const styles = await http.get('shirts/styles')
  return styles
}

export const getFits = async () => {
  const fits = await http.get('shirts/fits')
  return fits
}

export const getProducts = async () => {
  const products = await http.get('products')
  return products
}

export const getResults = async data => {
  const products = await http.post('search/shirts', data)
  console.log('API Response')
  console.log(products)
  return products
}

export const getFit = async id => {
  const fit = await http.get(`shirts/fits/${id}`)
  console.log('API Response Fit')
  console.log(fit)
  return fit
}

export { http }