import React from 'react'

function WidgetFit({ allFits, setFit, nextStep, selectedBrand, selectedStyle, configure }) {
  const displayFits = () => {
    return allFits.data
      .filter(fit => {
        return selectedBrand === fit.brandId && selectedStyle === fit.styleId
      })
      .sort()
      .map((fit, key) => {
        if (window.innerWidth > 763) {
          let fitborder = ''
          const num = allFits.data.filter(fit => {
            return selectedBrand === fit.brandId && selectedStyle === fit.styleId
          }).length

          if (num % 3 !== 0) {
            const rows = Math.floor(num / 3)
            var second_last_row_last_box = rows * 3
          }
          if (configure?.shopType === 'wordpress') {
            if (key === 0) {
              fitborder += 'border-top-left '
            }
            if (key === 0 && num === 3) {
              fitborder += 'border-bottom-left'
            }
            if (key === second_last_row_last_box - 1) {
              fitborder += 'border-bottom-right '
            }

            if (key === 2 && num > 3) {
              fitborder += 'border-top-right '
            }

            if (key === num - 1) {
              fitborder += 'border-bottom-right '
            }

            if (key === second_last_row_last_box) {
              fitborder += 'border-bottom-left '
            }
          } else {
            if (key === 0) {
              fitborder += 'border-top-left-harry '
            }
            if (key === 0 && num === 3) {
              fitborder += 'border-bottom-left-harry '
            }
            if (key === second_last_row_last_box - 1) {
              fitborder += 'border-bottom-right-harry '
            }

            if (key === 2 && num > 3) {
              fitborder += 'border-top-right-harry '
            }

            if (key === num - 1) {
              fitborder += 'border-bottom-right-harry '
            }

            if (key === second_last_row_last_box) {
              fitborder += 'border-bottom-left '
            }
          }

          return (
            <div
              key={key}
              className={`${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''} style-item style-fit ${fitborder}`}
              onClick={() => {
                console.log(fit)
                setFit(fit)
                nextStep()
              }}
            >
              {fit.name}
            </div>
          )
        } else {
          let num = allFits.data
            .filter(fit => {
              return selectedBrand === fit.brandId && selectedStyle === fit.styleId
            })
            .sort().length
          let bordernone = ''
          let border = ''
          let borderDull = ''

          if (key === 0 && key === num - 1) {
            border = 'border-top-right border-top-left border-bottom-right border-bottom-left'
          }
          if (key === 0) {
            border += 'border-top-right border-top-left'
          }
          if (key === num - 1) {
            border += 'border-bottom-right border-bottom-left'
          }
          console.log('the data is her', key !== num - 1)
          if (key !== num - 1) {
            bordernone += 'border-none'
          }
          if (configure?.shopType === 'wordpress') {
            if (key !== 0) {
              borderDull += 'border-dull'
            }
          } else {
            if (key !== 0) {
              borderDull += 'border-dull-harry'
            }
          }
          return (
            <div
              key={key}
              id={`fit ${key}`}
              className={`${configure?.website.includes('alderandgreen') ? 'style-item-alder' : ''} style-item style-fit-custom ${border} ${bordernone} border ${borderDull}`}
              onClick={() => {
                console.log(fit)
                setFit(fit)
                nextStep()
              }}
            >
              {fit.name}
            </div>
          )
        }
      })
  }

  return (
    <div
      className={
        configure?.shopType === 'wordpress'
          ? window.innerWidth > 763
            ? 'styles-container fullwidth'
            : 'styles-container'
          : window.innerWidth > 763
          ? 'styles-container-harry fullwidth'
          : 'styles-container-harry'
      }
    >
      <>{displayFits()}</>
    </div>
  )
}

export default WidgetFit
