const env = process.env.REACT_APP_STAGE
const domain = process.env.REACT_APP_DOMAIN

const appDomain = env ? (env === 'master' ? `https://${domain}` : `https://${env}-${domain}`) : 'http://localhost:3000'

const suffix = env ? (env === 'master' ? '' : env + '/') : ''

const formatParam = (param, lowercase = true) => {
  return lowercase
    ? param.replace(/\s/g, '').replace(/,/g, '-').replace(/\./g, '-').toLowerCase()
    : param.replace(/\s/g, '').replace(/,/g, '-').replace(/\./g, '-')
}


const redirectURLWordpress = (url = '', style, fit, size) =>
  `https://${url}/shop/?swoof=1&product_cat=${formatParam(style)}&product_tag=${formatParam(fit)}&pa_size=${formatParam(
    size,
    false
  )}`
const redirectURLShopify = (url = '', style, fit, size) =>
  `https://${url}/collections/all-shirts/style_${formatParam(style)}+fit_${formatParam(fit)}+size_${formatParam(
      size,
      false
  )}`
const redirectURLShopifyAlder = (url = '', style, fit, size) =>
`https://${url}/a/search/all-shirts?filter_fit=${formatParam(fit, false)}&filter_collar_size=${size}`
const redirectURL = (url = '', style, fit, size) =>
    `https://${url}/collections/all-shirts/style_${formatParam(style)}+fit_${formatParam(fit)}+size_${formatParam(
        size,
        false
    )}`

export { appDomain, suffix, redirectURLShopify, redirectURLWordpress, redirectURL, redirectURLShopifyAlder }