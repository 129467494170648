import React from 'react'
import '../../styles/_WidgetHeader.scss'
import PowerByWidget from '../PowerByWidget'

function WidgetNavigation({ headerText, step, setModal, brand, colors }) {

  return (
    <div className='header-container'>
      <div className='logo-container'>
        <img className={brand?.website.includes('alderandgreen') ? 'brand-logo-alder' : (brand?.shopType === 'wordpress' ? 'brand-logo' : 'brand-logo-harry')} src={``} alt={''} />
          
          {!brand?.website.includes('alderandgreen') ? 
            <svg
              width='15'
              className='close_btn'
              height='14'
              viewBox='0 0 15 14'
              fill={colors.appBackButtonColor}
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => {
                setModal()
              }}
            >
              <line
                x1='1.53033'
                y1='1.46967'
                x2='13.5303'
                y2='13.4697'
                stroke={colors.appBackButtonColor || '#545454'}
                stroke-opacity='2'
                stroke-width='1.5'
              />
              <line
                x1='1.46967'
                y1='13.4697'
                x2='13.4697'
                y2='1.46968'
                stroke={colors.appBackButtonColor || '#545454'}
                stroke-opacity='2'
                stroke-width='1.5'
              />
            </svg> :
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="15" 
              height="14"
              className='close_btn close_btn_alder'
              viewBox="0 0 15 14" 
              fill="none"
              onClick={() => {
                setModal()
              }}
            >
            <line 
              x1="1.53033"
              y1="1.46967"
              x2="13.5303" 
              y2="13.4697" 
              stroke="#00264D" 
              stroke-width="1.5"
            />
            <line 
              x1="1.46967" 
              y1="13.4697"
              x2="13.4697" 
              y2="1.46968" 
              stroke="#00264D" 
              stroke-width="1.5"/>
            </svg>
          }
          <PowerByWidget wrapperClass={'web-powered-by-container'} infoIconClass={'web-info-icon-wrapper'} />
      </div>
      {step < 4 && (
        <div className='banner-container'>
          <h3 className={brand?.website.includes('alderandgreen') ? 'banner-text-alder' : 'banner-text' }>
            {step > 0}
            {headerText()}
          </h3>
          {step >= 0 && (
            <div className='stepper'>
              {[0, 1, 2, 3].map(index => {
                return (
                  <div
                    className={brand?.website.includes('alderandgreen') ? 'step-alder' : (brand?.shopType === 'wordpress' ? 'step' : 'step-harry')}
                    style={index === step ? { backgroundColor: brand?.website.includes('alderandgreen') ? '#00264D' : (brand?.shopType === 'wordpress' ? '#fff' : '#02231F'), border: '0px' } : {}}
                  ></div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default WidgetNavigation
