import React, { useEffect, useState, useRef } from 'react'
import '../styles/_PowerByWidget.scss'
import SuitableLogo from '../assets/suitable-logo.png'

const PowerByWidget = props => {
  const widgetRef = useRef()
  const [showPowerWidget, setShowPowerWidget] = useState(false)
  useEffect(() => {
    if (showPowerWidget) {
      document.addEventListener('click', handleOutsideClick)
    }
    //eslint-disable-next-line
  }, [showPowerWidget])
  const handleOutsideClick = event => {
    console.log('hjsdjhsd')
    if (showPowerWidget && !widgetRef?.current?.contains(event?.target) && widgetRef) {
      setShowPowerWidget(false)
      document.removeEventListener('click', handleOutsideClick)
    }
  }
  return (
    <div className={showPowerWidget ? `${props.wrapperClass}` : `${props.infoIconClass}`}>
      {showPowerWidget ? (
        <div ref={widgetRef} className='widgetRef'>
          <img src={SuitableLogo} alt={''} />
          <p>
            Powered by <br />
            <span onClick={() => window.open('https://www.suitable.tech/', '_blank')}>Suitable.tech</span>
          </p>
        </div>
      ) : (
        <div onClick={() => setShowPowerWidget(true)} ref={widgetRef}>
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9.49469 15V8.21H10.5027V15H9.49469ZM10.0127 6.754C9.81669 6.754 9.64869 6.68867 9.50869 6.558C9.37802 6.42733 9.31269 6.25467 9.31269 6.04C9.31269 5.83467 9.37802 5.67133 9.50869 5.55C9.64869 5.41933 9.81669 5.354 10.0127 5.354C10.218 5.354 10.386 5.41933 10.5167 5.55C10.6474 5.67133 10.7127 5.83467 10.7127 6.04C10.7127 6.25467 10.6474 6.42733 10.5167 6.558C10.386 6.68867 10.218 6.754 10.0127 6.754Z'
              fill='#00264D'
            />
            <circle cx='10' cy='10' r='9.5' stroke='#00264D' />
          </svg>
        </div>
      )}
    </div>
  )
}

export default PowerByWidget
